import useFilters from "recoilStore/useFilters";
import { useEffect, useMemo, useRef, useState } from "react";
import ViewTab from "components/atoms/ViewTab";
import Filters from "components/molecules/Filters/Filters";
import Export from "components/molecules/Export/Export";
import { useDeleteBuildingModels } from "mutations/useDeleteBuildingModels";
import posthog from "posthog-js";
import { Header, InlineBoldParagraph, Paragraph } from "components/atoms/Typography";
import { AddIcon, Delete, EditIcon, MapIcon, TableIcon } from "components/atoms/Icon";
import DangerousModal from "components/molecules/DangerousModal";
import { useClickOutside } from "hooks/useClickOutside";
import { useCreateBuildingModelTags } from "mutations/useCreateBuildingModelTags";
import { useDeleteBuildingModelTags } from "mutations/useDeleteBuildingModelTags";
import Modal from "components/molecules/Modal";
import { BuildingAddTagsOverlay, Tag } from "pages/BuildingPage/BuildingTags";
import { Button } from "components/atoms/Button";
import { BuildingsTableData } from "./helpers";
import TabType from "./PortfolioPage.types";
import AddPropertiesFundsModal from "./AddPropertiesFundsModal";

interface ViewHeaderProps {
    numberOfBuildings?: number;
    numberOfProperties?: number;
    selectedProperties: Property[];
    setSelectedProperties: React.Dispatch<React.SetStateAction<Property[]>>;
    numberOfFunds?: number;
    handleViewChange: (view: PortfolioPageView) => void;
    currentView: PortfolioPageView;
    selectedTab: TabType;
    tabClickHandler: (tab: TabType) => void;
    buildingName?: string;
    numberOfSelectedBuildings: number;
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: React.Dispatch<
        React.SetStateAction<BuildingsTableData[]>
    >;
    selectedTags: string[];
    selectedTagsAreMixed: boolean;
}

function ViewHeader({
    numberOfBuildings,
    numberOfProperties,
    selectedProperties,
    setSelectedProperties,
    numberOfFunds,
    handleViewChange,
    currentView,
    selectedTab,
    tabClickHandler,
    buildingName,
    numberOfSelectedBuildings,
    selectedBuildingRows,
    setSelectedBuildingRows,
    selectedTags,
    selectedTagsAreMixed,
}: ViewHeaderProps) {
    const { numberOfFilters } = useFilters("buildings");

    const clearSelectedRows = () => {
        setSelectedBuildingRows([]);
    };

    const selectedBuildingModelUids = useMemo(() => {
        if (selectedBuildingRows.length < 1) return [];

        if ((selectedBuildingRows[0] as BuildingsTableData).buildingModelUid) {
            return (selectedBuildingRows as BuildingsTableData[]).map(
                ({ buildingModelUid }) => buildingModelUid
            );
        }
        return selectedProperties.flatMap((row) =>
            row.buildingModels.map(({ buildingModelUid }) => buildingModelUid)
        );
    }, [selectedBuildingRows]);

    return (
        <div
            className="view-header"
            style={{
                borderBottom:
                    numberOfFilters > 0
                        ? "1px solid var(--audette-gray-300)"
                        : undefined,
            }}
        >
            <div className="view-header__tabs">
                <ViewTab
                    id="portfolio-buildings-tab"
                    name={
                        selectedTab === "buildings"
                            ? `Buildings (${numberOfBuildings})`
                            : "Buildings"
                    }
                    selected={selectedTab === "buildings"}
                    onClick={() => tabClickHandler("buildings")}
                />
                <ViewTab
                    id="portfolio-properties-tab"
                    name={
                        selectedTab === "properties"
                            ? `Properties (${numberOfProperties})`
                            : "Properties"
                    }
                    selected={selectedTab === "properties"}
                    onClick={() => tabClickHandler("properties")}
                />
                <ViewTab
                    id="portfolio-funds-tab"
                    name={
                        selectedTab === "funds"
                            ? `Funds (${numberOfFunds})`
                            : "Funds"
                    }
                    selected={selectedTab === "funds"}
                    onClick={() => tabClickHandler("funds")}
                />
            </div>{" "}
            {selectedTab === "buildings" && <Filters />}
            {selectedBuildingRows.length !== 0 &&
                selectedTab === "buildings" && (
                    <AddPropertiesFundsModal
                        type="properties"
                        selectedProperties={selectedProperties}
                        setSelectedProperties={setSelectedProperties}
                        selectedBuildings={selectedBuildingRows}
                        setSelectedBuildings={setSelectedBuildingRows}
                    />
                )}
            {selectedProperties.length !== 0 &&
                selectedTab === "properties" && (
                    <AddPropertiesFundsModal
                        type="funds"
                        selectedProperties={selectedProperties}
                        setSelectedProperties={setSelectedProperties}
                        selectedBuildings={selectedBuildingRows}
                        setSelectedBuildings={setSelectedBuildingRows}
                    />
                )}
            {selectedBuildingRows.length !== 0 &&
                selectedTab === "buildings" && (
                    <AddEditTags
                        selectedTags={selectedTags}
                        selectedTagsAreMixed={selectedTagsAreMixed}
                        selectedBuildingRows={
                            selectedBuildingRows as BuildingsTableData[]
                        }
                        setSelectedBuildingRows={setSelectedBuildingRows}
                    />
                )}
            {selectedTab !== "funds" && (
                <ViewButton
                    currentView={currentView}
                    handleViewChange={handleViewChange}
                />
            )}
            <Export />
            {numberOfSelectedBuildings > 0 && selectedTab === "buildings" && (
                <DeleteBuildings
                    numberOfSelectedIds={numberOfSelectedBuildings}
                    buildingName={buildingName}
                    selectedBuildingModelUids={selectedBuildingModelUids}
                    clearSelectedRows={clearSelectedRows}
                />
            )}
        </div>
    );
}





interface DeleteProps {
    numberOfSelectedIds: number;
    buildingName?: string;
    clearSelectedRows: () => void;
    selectedBuildingModelUids: string[];
}

function DeleteBuildings({
    numberOfSelectedIds,
    buildingName,
    clearSelectedRows,
    selectedBuildingModelUids,
}: DeleteProps) {
    const { mutate: deleteBuildingModels } = useDeleteBuildingModels();

    const [deleteBuildingsModalIsOpen, setDeleteBuildingsModalIsOpen] =
        useState(false);

    const handleDeleteBuildings = () => {
        deleteBuildingModels(selectedBuildingModelUids);
        posthog.capture('Buildings deleted', { added_buildings_count: -selectedBuildingModelUids.length })
        clearSelectedRows();
        setDeleteBuildingsModalIsOpen(false);
    };

    const modalOptions = useMemo(
        () => buildModalOptions(numberOfSelectedIds, buildingName),
        [numberOfSelectedIds, buildingName]
    );

    return (
        <>
            <Paragraph style={{ color: "var(--audette-content-negative)" }}>
                <button
                    id="delete-buildings-button"
                    data-ph-capture-attribute-buildings-count={
                        numberOfSelectedIds
                    }
                    type="button"
                    onClick={() => setDeleteBuildingsModalIsOpen(true)}
                >
                    <Delete />
                    Delete {numberOfSelectedIds}
                </button>
            </Paragraph>
            <DangerousModal
                open={deleteBuildingsModalIsOpen}
                onCancel={() => setDeleteBuildingsModalIsOpen(false)}
                onDelete={handleDeleteBuildings}
                options={modalOptions}
                styleType="primary-negative"
            />
        </>
    );
}

const buildModalOptions = (
    numberOfSelectedIds: number,
    buildingName?: string
) => ({
    title: `Delete building${numberOfSelectedIds > 1 ? "s" : ""}?`,
    body: (
        <>
            This will{" "}
            <InlineBoldParagraph>
                permanently remove{" "}
                {buildingName
                    ? `${buildingName} building`
                    : "all selected buildings"}
            </InlineBoldParagraph>
            . Do you wish to proceed?
        </>
    ),
    deleteButtonText: `Delete building${numberOfSelectedIds > 1 ? "s" : ""}`,
});

interface ViewButtonProps {
    currentView: PortfolioPageView;
    handleViewChange: (view: PortfolioPageView) => void;
}

function ViewButton({ currentView, handleViewChange }: ViewButtonProps) {
    return currentView === "table" ? (
        <button
            id="buildings-view-map-button"
            type="button"
            onClick={() => handleViewChange("map")}
        >
            <MapIcon />
            <Paragraph>Map</Paragraph>
        </button>
    ) : (
        <button
            id="buildings-view-table-button"
            type="button"
            onClick={() => handleViewChange("table")}
        >
            <TableIcon />
            <Paragraph>Table</Paragraph>
        </button>
    );
}

interface AddEditTagsProps {
    selectedTagsAreMixed: boolean;
    selectedTags: string[];
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: (rows: BuildingsTableData[]) => void;
}

function AddEditTags({
    selectedTagsAreMixed,
    selectedTags,
    selectedBuildingRows,
    setSelectedBuildingRows,
}: AddEditTagsProps) {
    const [addTagsModalIsOpen, setAddTagsModalIsOpen] = useState(false);
    return (
        <>
            <button type="button" onClick={() => setAddTagsModalIsOpen(true)}>
                {selectedTags.length === 0 ? (
                    <AddIcon color="var(--audette-gray-600)" />
                ) : (
                    <EditIcon />
                )}
                <Paragraph>
                    {selectedTags.length === 0 ? "Add tags" : "Edit tags"}
                </Paragraph>
            </button>
            <AddEditTagsModal
                open={addTagsModalIsOpen}
                onCancel={() => setAddTagsModalIsOpen(false)}
                tags={selectedTags}
                tagsAreMixed={selectedTagsAreMixed}
                selectedBuildingRows={selectedBuildingRows}
                setSelectedBuildingRows={setSelectedBuildingRows}
            />
        </>
    );
}

interface AddEditTagsModalProps {
    open: boolean;
    onCancel: () => void;
    tags: string[];
    tagsAreMixed: boolean;
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: (rows: BuildingsTableData[]) => void;
}

function AddEditTagsModal({
    open,
    onCancel,
    tags,
    tagsAreMixed,
    selectedBuildingRows,
    setSelectedBuildingRows,
}: AddEditTagsModalProps) {
    const [isEditingTags, setIsEditingTags] = useState(false);
    const ref = useRef(null);
    useClickOutside(ref, () => setIsEditingTags(false));

    const [localTags, setLocalTags] = useState(tagsAreMixed ? [] : tags);

    useEffect(() => {
        setLocalTags(tagsAreMixed ? [] : tags);
    }, [tags, open]);

    const selectedBuildingUids = useMemo(
        () =>
            selectedBuildingRows.map(
                ({ buildingModelUid }) => buildingModelUid
            ),
        [selectedBuildingRows]
    );

    const { mutate: createTags } =
        useCreateBuildingModelTags(selectedBuildingUids);
    const { mutate: deleteTags } =
        useDeleteBuildingModelTags(selectedBuildingUids);

    const createdTags = useMemo(() => {
        if (tagsAreMixed) return [...localTags];
        return localTags.filter((localTag) => !tags.includes(localTag));
    }, [localTags, tags]);

    const removeTag = (e: any, tag: string) => {
        e.stopPropagation();
        setLocalTags((prev) => {
            const copy = [...prev];
            const index = copy.findIndex((v) => v === tag);
            copy.splice(index, 1);
            return copy;
        });
    };

    const handleSaveTags = () => {
        if (createdTags.length !== 0) {
            const createTagsData = selectedBuildingRows
                .map(({ buildingModelUid, tags }) => {
                    const tagsToCreate = createdTags.filter(
                        (tag) => !tags.includes(tag)
                    );
                    return {
                        tags: tagsToCreate,
                        buildingModelUid,
                    };
                })
                .filter((v) => v.tags.length !== 0);
            createTags(createTagsData);
        }

        if (!tagsAreMixed) {
            // can't remove existing tags when they are mixed
            const tagsRemoved = tags.filter((tag) => !localTags.includes(tag));
            deleteTags(
                selectedBuildingUids.map((buildingModelUid) => ({
                    tags: tagsRemoved,
                    buildingModelUid,
                }))
            );
        }
        setSelectedBuildingRows([]);
        onCancel();
    };

    const deleteAllTags = () => {
        const tagData = selectedBuildingUids.map((buildingModelUid) => ({
            buildingModelUid,
            tags,
        }));
        setSelectedBuildingRows([]);
        deleteTags(tagData);
        onCancel();
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            title={tags.length === 0 ? "Add tags" : "Edit tags"}
            footer={
                <AddEditTagsModalFooter
                    onSave={handleSaveTags}
                    onCancel={onCancel}
                    onDeleteAll={tags.length === 0 ? undefined : deleteAllTags}
                />
            }
        >
            <div
                className="bulk-edit-tags"
                style={{ position: "relative" }}
                ref={ref}
            >
                <Header size="small">Tags</Header>
                <div className="bulk-edit-tags__select">
                    <button
                        type="button"
                        onClick={() => setIsEditingTags(true)}
                    >
                        {localTags.length !== 0 && (
                            <>
                                {localTags.map((tag) => (
                                    <Tag
                                        key={tag}
                                        value={tag}
                                        onClickClose={(e) => removeTag(e, tag)}
                                    />
                                ))}
                            </>
                        )}
                        {localTags.length === 0 && (
                            <Paragraph
                                style={{ color: "var(--audette-gray-400)" }}
                            >
                                Select existing or create new
                            </Paragraph>
                        )}
                    </button>
                </div>
                {tagsAreMixed && (
                    <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                        Selected buildings contain mixed tags, new tags will be
                        added to them.
                    </Paragraph>
                )}
                {isEditingTags && (
                    <BuildingAddTagsOverlay
                        buildingTags={localTags}
                        setBuildingTags={setLocalTags}
                    />
                )}
            </div>
        </Modal>
    );
}

interface AddEditTagsModalFooterProps {
    onSave: () => void;
    onCancel: () => void;
    onDeleteAll?: () => void;
}

function AddEditTagsModalFooter({
    onSave,
    onCancel,
    onDeleteAll,
}: AddEditTagsModalFooterProps) {
    return (
        <div className="bulk-edit-tags__footer">
            <Button type="primary" onClick={onSave}>
                Save
            </Button>
            <Button
                type="link"
                onClick={onCancel}
                style={{ color: "var(--audette-black)" }}
            >
                Cancel
            </Button>
            {onDeleteAll && (
                <Button
                    type="link"
                    onClick={onDeleteAll}
                    style={{ color: "var(--audette-content-negative)" }}
                >
                    Delete all tags
                </Button>
            )}
        </div>
    );
}

export default ViewHeader;
