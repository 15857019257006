import mutationActiveAccountFn from "ApiLayer/ActiveOrganization/updateActiveOrganization";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useMutation, useQueryClient } from "react-query";
import { showMutationAlert } from "utils/helpers";

export const useUpdateActiveOrganization = () => {
    const accessToken = useAccessToken();

    const alert = useAlert();

    const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: async () => {
            alert.success("active organization updated.");

            await queryClient.invalidateQueries({ queryKey: ["user"] });

            window.location.reload();
        },
        mutationFn: async (activeOrganization: UserInput) => (
            mutationActiveAccountFn(accessToken, activeOrganization)
        )
    });
};
