import { useState, useEffect, ReactNode, useMemo, ChangeEvent } from "react";
import classNames from "classnames";
import posthog from "posthog-js";

// components
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Sidebar from "components/molecules/Sidebar";
import MultiselectDropdown from "components/molecules/MultiselectDropdown";
import { DataCoverageIcon } from "components/molecules/DataCoverage/DataCoverage";
import { Button } from "components/atoms/Button";
import { Header } from "components/atoms/Typography";
import Checkbox from "components/atoms/Checkbox";
import SingleSelectDropdown from "components/atoms/Dropdown";
import MUITheme from "components/MUITheme";

// hooks
import useFilters, { emptyFilters } from "recoilStore/useFilters";
import useUniqueTags from "recoilStore/useUniqueTags";
import { useLocation } from "react-router-dom";

// constants
import { getDisplayInfo } from "utils/formatting";

import {
    buildingsFilterKeyToInfoMap,
    filterConditionToDisplayValueMap,
    MultiSelectConditions,
    StringSelectConditions,
    NumericSelectConditions,
    DateSelectConditions,
    DataCoverageEnums,
    measuresFilterKeyToInfoMap,
} from "./FilterSidebar.constants";

// helpers

import {
    NumericFilterIsIncomplete,
    buildMultiSelectDropdownItems,
    removeFilter,
    buildIsFieldActiveMap,
    getFilterNameFromKey,
    cloneFilters,
} from "./FilterSidebar.helpers";

// styling
import "./index.scss";

interface FiltersSidebarProps {
    closeSidebar: () => void;
    sidebarOpen: boolean;
    page: Page;
}

function FiltersSidebar({
    page,
    closeSidebar,
    sidebarOpen,
}: FiltersSidebarProps) {
    const { setFilters, filters } = useFilters(page);
    const [localFilters, setLocalFilters] = useState(cloneFilters(filters));
    const [isFieldActiveMap, setIsFieldActiveMap] = useState(
        buildIsFieldActiveMap(filters)
    );

    const numberOfLocalFilters = useMemo(() => {
        const {
            numericFilters,
            stringFilters,
            dateFilters,
            dataCoverageFilter,
        } = localFilters;
        return (
            numericFilters.length +
            stringFilters.length +
            dateFilters.length +
            dataCoverageFilter.length
        );
    }, [localFilters]);

    useEffect(() => {
        setLocalFilters(cloneFilters(filters));
        setIsFieldActiveMap(buildIsFieldActiveMap(filters));
    }, [filters]);

    const handleApplyFilters = () => {
        setFilters(localFilters);
        
        const posthogSimplifiedFilters = {
            ...localFilters,
            numericFilters: localFilters.numericFilters.map(({ field }) => field),
            stringFilters: localFilters.stringFilters.map(({ field }) => field),
        };

        posthog.capture('Filters applied',posthogSimplifiedFilters);
        closeSidebar();
        setIsFieldActiveMap(buildIsFieldActiveMap(localFilters));
    };

    const handleClearAll = () => {
        setLocalFilters(emptyFilters);
        posthog.capture('Filters cleared');
        setIsFieldActiveMap(buildIsFieldActiveMap(emptyFilters));
    };

    const handleCancel = () => {
        posthog.capture('Filters closed');
        setLocalFilters({ ...filters });
        closeSidebar();
        setIsFieldActiveMap(buildIsFieldActiveMap(filters));
    };

    const handleClearFilter = (filterField: string) =>
        setLocalFilters((prev) => removeFilter(prev, filterField));

    const handleSelectFilter = (checked: boolean, key: string) => {
        setIsFieldActiveMap((prev) => {
            const copy = new Map(prev);
            copy.set(key, checked);
            return copy;
        });
    };

    const filtersMap = useMemo(() => {
        if (page === "measures") return measuresFilterKeyToInfoMap;
        return buildingsFilterKeyToInfoMap;
    }, [page]);

    return (
        <Sidebar
            onCancel={handleCancel}
            sidebarOpen={sidebarOpen}
            title={`Filters ${
                numberOfLocalFilters > 0 ? `(${numberOfLocalFilters})` : ""
            }`}
        >
            <div className="filters-sidebar__body">
                {Object.keys(filtersMap).map((key) => {
                    const { type, field } = buildingsFilterKeyToInfoMap[key];
                    if (key === "createdAt" && page === "reports") return null;
                    return (
                        <FilterContainer
                            name={getFilterNameFromKey(key)}
                            checked={!!isFieldActiveMap.get(field)}
                            setChecked={(checked) =>
                                handleSelectFilter(checked, field)
                            }
                            key={field}
                            handleUncheck={() => handleClearFilter(field)}
                        >
                            <Filter
                                filters={localFilters}
                                setFilters={setLocalFilters}
                                filterKey={key}
                                filterField={field}
                                type={type}
                            />
                        </FilterContainer>
                    );
                })}
            </div>
            <FiltersSidebarFooter
                onClickApply={handleApplyFilters}
                onClickCancel={handleCancel}
                onClickClearAll={handleClearAll}
                page={page}
                localFilters={localFilters}
            />
        </Sidebar>
    );
}

interface FilterProps {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    filterField:
        | StringFilterField
        | NumericFilterField
        | DateFilterField
        | "data_coverage";
    filterKey: string;
    type: "multi-select" | "text" | "numeric" | "date";
}

function Filter({
    filters,
    setFilters,
    filterField,
    filterKey,
    type,
}: FilterProps) {
    if (type === "date")
        return (
            <DateFilter
                filters={filters}
                setFilters={setFilters}
                filterField={filterField as DateFilterField}
                filterKey={filterKey}
            />
        );

    if (type === "numeric")
        return (
            <NumericFilter
                filters={filters}
                setFilters={setFilters}
                filterField={filterField as NumericFilterField}
                filterKey={filterKey}
            />
        );

    if (type === "multi-select")
        return (
            <MultiSelectFilter
                filters={filters}
                setFilters={setFilters}
                filterField={filterField as StringFilterField}
                filterKey={filterKey}
            />
        );

    if (type === "text")
        return (
            <StringFilter
                filters={filters}
                setFilters={setFilters}
                filterField={filterField as StringFilterField}
                filterKey={filterKey}
            />
        );

    return null;
}

interface FilterContainerProps {
    children: ReactNode;
    name: string;
    handleUncheck: () => void;
    checked: boolean;
    setChecked: (checked: boolean) => void;
}

function FilterContainer({
    children,
    name,
    handleUncheck,
    checked,
    setChecked,
}: FilterContainerProps) {
    useEffect(() => {
        if (checked === false) handleUncheck();
    }, [checked]);

    return (
        <div className="filter-container">
            <div className="filter-container__title">
                <Checkbox
                    checked={checked}
                    onChange={(checked) => {
                        setChecked(checked);
                    }}
                />
                <Header
                    size="small"
                    dataTestId={`filter-sidebar-option-label-${name}`}
                >
                    {name}
                </Header>
            </div>
            <div
                className={classNames(
                    "filter-container__body",
                    checked && "show"
                )}
            >
                {children}
            </div>
        </div>
    );
}

interface FiltersSidebarFooterProps {
    onClickApply: () => void;
    onClickCancel: () => void;
    onClickClearAll: () => void;
    page: Page;
    localFilters: Filters;
}

function FiltersSidebarFooter({
    onClickApply,
    onClickCancel,
    onClickClearAll,
    page,
    localFilters,
}: FiltersSidebarFooterProps) {
    return (
        <div className="filters-sidebar__footer">
            <div className="filters-sidebar__footer__buttons">
                <Button
                    type="primary"
                    onClick={onClickApply}
                    id={`${page}-apply-filters-button`}
                    data-ph-capture-attribute-filters={JSON.stringify(
                        localFilters
                    )}
                >
                    Apply
                </Button>
                <Button
                    onClick={onClickCancel}
                    type="link"
                    id={`${page}-cancel-filters-button`}
                >
                    Cancel
                </Button>
            </div>
            <Button
                onClick={onClickClearAll}
                type="link"
                id={`${page}-reset-filters-button`}
            >
                Reset filters
            </Button>
        </div>
    );
}

interface NumericStringMultiFilterProps<Field> {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    filterField: Field;
    filterKey: string;
}

function NumericFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<NumericFilterField>) {
    const currentFilter = useMemo(
        () => filters.numericFilters.find(({ field }) => field === filterField),
        [filters]
    );

    const [startValue, setStartValue] = useState(currentFilter?.value[0]);
    const [endValue, setEndValue] = useState(currentFilter?.value[1]);

    const [dropdownInput, setDropdownInput] = useState(
        currentFilter?.condition
    );

    useEffect(() => {
        setDropdownInput(currentFilter?.condition);
        setStartValue(currentFilter?.value[0]);
        setEndValue(currentFilter?.value[1]);
    }, [currentFilter]);

    const dropdownItems = NumericSelectConditions.map((id) => ({
        id,
        displayValue: filterConditionToDisplayValueMap[id],
    }));

    const { placeholder } = getDisplayInfo(filterKey);

    useEffect(() => {
        if (NumericFilterIsIncomplete(startValue, endValue, dropdownInput))
            return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: NumericFilter = {
                value:
                    dropdownInput === "between"
                        ? [startValue!, endValue!]
                        : [startValue!],
                condition: dropdownInput!,
                field: filterField,
                key: filterKey,
            };

            const index = copy.numericFilters.findIndex(
                ({ field }) => field === filterField
            );
            if (index === -1) copy.numericFilters.push(newFilter);
            else copy.numericFilters[index] = newFilter;
            return copy;
        });
    }, [dropdownInput, startValue, endValue]);

    const updateStartValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === undefined || value === "") setStartValue(undefined);
        else setStartValue(Number(value));
    };

    const updateEndValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === undefined || value === "") setEndValue(undefined);
        else setEndValue(Number(value));
    };

    return (
        <div className="filter">
            <SingleSelectDropdown
                items={dropdownItems}
                onSelectItem={(item) => setDropdownInput(item.id)}
                defaultItem={dropdownItems.find(
                    ({ id }) => id === dropdownInput
                )}
                classname="filter__dropdown"
            />
            <input
                type="number"
                value={startValue || ""}
                className="filter-input"
                onChange={updateStartValue}
                placeholder={
                    dropdownInput === "between" ? "Start" : placeholder
                }
                maxLength={50}
            />
            {dropdownInput === "between" && (
                <input
                    type="numeric"
                    value={endValue || ""}
                    className="filter-input"
                    onChange={updateEndValue}
                    placeholder="End"
                    maxLength={50}
                />
            )}
        </div>
    );
}
function MultiSelectFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<StringFilterField>) {
    if (filterKey === "tags") {
        return (
            <TagMultiSelectFilter filters={filters} setFilters={setFilters} />
        );
    }

    if (filterKey === "dataCoverage") {
        return (
            <DataCoverageMultiSelectFilter
                filters={filters}
                setFilters={setFilters}
            />
        );
    }

    return (
        <GenericMultiSelectFilter
            filters={filters}
            setFilters={setFilters}
            filterField={filterField}
            filterKey={filterKey}
        />
    );
}

interface MultiSelectFilterProps {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

function TagMultiSelectFilter({ filters, setFilters }: MultiSelectFilterProps) {
    const currentFilter = useMemo(
        () => filters.stringFilters.find(({ field }) => field === "tag"),
        [filters]
    );

    const [selectedItemsMultiSelect, setSelectedItemsMultiSelect] = useState(
        currentFilter?.value
    );

    const { tags } = useUniqueTags();
    const multiSelectDropdownItems = useMemo(() => {
        const { formatFunction } = getDisplayInfo("tags");
        return tags.tags.map((tag) => ({
            key: tag,
            displayValue: formatFunction(tag),
        }));
    }, [tags]);

    useEffect(() => {
        if (!selectedItemsMultiSelect) return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: StringFilter = {
                value: selectedItemsMultiSelect,
                condition: "equal",
                field: "tag",
                key: "tags",
            };

            const index = copy.stringFilters.findIndex(
                ({ field }) => field === "tag"
            );
            if (index === -1) copy.stringFilters.push(newFilter);
            else copy.stringFilters[index] = newFilter;
            return copy;
        });
    }, [selectedItemsMultiSelect]);

    useEffect(() => {
        setSelectedItemsMultiSelect(currentFilter?.value);
    }, [currentFilter]);

    return (
        <div className="filter">
            <MultiselectDropdown
                items={multiSelectDropdownItems}
                selectedKeys={selectedItemsMultiSelect || []}
                handleChange={(keys) => setSelectedItemsMultiSelect(keys)}
                fixedHeight={false}
            />
        </div>
    );
}

function DataCoverageMultiSelectFilter({
    filters,
    setFilters,
}: MultiSelectFilterProps) {
    const { pathname } = useLocation();
    const page = pathname.includes("reports") ? "reports" : "buildings";

    const currentFilter = filters.dataCoverageFilter;
    const [selectedItemsMultiSelect, setSelectedItemsMultiSelect] =
        useState(currentFilter);

    let dropdownItems = DataCoverageEnums.slice();
    if (page === "reports") {
        dropdownItems = dropdownItems.filter(
            (item) => !["error", "processing"].includes(item)
        );
    }
    const multiSelectDropdownItems = dropdownItems.map((item) => ({
        key: item,
        displayValue: <DataCoverageIcon identifier={item} />,
    }));

    useEffect(() => {
        if (!selectedItemsMultiSelect) return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);
            copy.dataCoverageFilter =
                selectedItemsMultiSelect as DataCoverageEnum[];
            return copy;
        });
    }, [selectedItemsMultiSelect]);

    useEffect(() => {
        setSelectedItemsMultiSelect(currentFilter);
    }, [currentFilter]);

    return (
        <div className="filter">
            <MultiselectDropdown
                items={multiSelectDropdownItems}
                selectedKeys={selectedItemsMultiSelect || []}
                handleChange={(keys) =>
                    setSelectedItemsMultiSelect(keys as DataCoverageEnum[])
                }
                fixedHeight={false}
            />
        </div>
    );
}

function GenericMultiSelectFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<StringFilterField>) {
    const { pathname } = useLocation();
    const currentFilter = useMemo(
        () => filters.stringFilters.find(({ field }) => field === filterField),
        [filters]
    );

    const [dropdownInput, setDropdownInput] = useState(
        currentFilter?.condition
    );

    const [selectedItemsMultiSelect, setSelectedItemsMultiSelect] = useState(
        currentFilter?.value
    );

    const dropdownItems = MultiSelectConditions.map((id) => ({
        id,
        displayValue: filterConditionToDisplayValueMap[id],
    }));

    const multiSelectDropdownItems = useMemo(() => {
        const page = pathname.includes("reports") ? "reports" : "buildings";
        return buildMultiSelectDropdownItems(filterKey, page);
    }, [filterKey]);

    useEffect(() => {
        if (!selectedItemsMultiSelect || !dropdownInput) return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: StringFilter = {
                value: selectedItemsMultiSelect,
                condition: dropdownInput,
                field: filterField,
                key: filterKey,
            };

            const index = copy.stringFilters.findIndex(
                ({ field }) => field === filterField
            );
            if (index === -1) copy.stringFilters.push(newFilter);
            else copy.stringFilters[index] = newFilter;
            return copy;
        });
    }, [selectedItemsMultiSelect, dropdownInput]);

    useEffect(() => {
        setDropdownInput(currentFilter?.condition);
        setSelectedItemsMultiSelect(currentFilter?.value);
    }, [currentFilter]);

    return (
        <div className="filter">
            <SingleSelectDropdown
                items={dropdownItems}
                onSelectItem={(item) => setDropdownInput(item.id)}
                defaultItem={dropdownItems.find(
                    ({ id }) => id === dropdownInput
                )}
                classname="filter__dropdown"
            />
            <MultiselectDropdown
                items={multiSelectDropdownItems}
                selectedKeys={selectedItemsMultiSelect || []}
                handleChange={(keys) => setSelectedItemsMultiSelect(keys)}
            />
        </div>
    );
}

function StringFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<StringFilterField>) {
    const currentFilter = useMemo(
        () => filters.stringFilters.find(({ field }) => field === filterField),
        [filters]
    );

    const [value, setValue] = useState(currentFilter?.value[0]);

    const [dropdownInput, setDropdownInput] = useState(
        currentFilter?.condition
    );

    useEffect(() => {
        setDropdownInput(currentFilter?.condition);
        setValue(currentFilter?.value[0]);
    }, [currentFilter]);

    const dropdownItems = StringSelectConditions.map((id) => ({
        id,
        displayValue: filterConditionToDisplayValueMap[id],
    }));

    const { placeholder } = getDisplayInfo(filterKey);

    useEffect(() => {
        if (value === "" || value === undefined || dropdownInput === undefined)
            return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: StringFilter = {
                value: [value],
                condition: dropdownInput,
                field: filterField,
                key: filterKey,
            };

            const index = copy.stringFilters.findIndex(
                ({ field }) => field === filterField
            );
            if (index === -1) copy.stringFilters.push(newFilter);
            else copy.stringFilters[index] = newFilter;
            return copy;
        });
    }, [dropdownInput, value]);

    const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === undefined || value === "") setValue(undefined);
        else setValue(value);
    };

    return (
        <div className="filter">
            <SingleSelectDropdown
                items={dropdownItems}
                onSelectItem={(item) => setDropdownInput(item.id)}
                defaultItem={dropdownItems.find(
                    ({ id }) => id === dropdownInput
                )}
                classname="filter__dropdown"
            />
            <input
                value={value || ""}
                className="filter-input"
                onChange={updateValue}
                placeholder={placeholder}
                maxLength={50}
            />
        </div>
    );
}

function DateFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<DateFilterField>) {
    const currentFilter = useMemo(
        () => filters.dateFilters.find(({ field }) => field === filterField),
        [filters]
    );

    const [value, setValue] = useState(currentFilter?.value[0]);
    const [endValue, setEndValue] = useState(currentFilter?.value[1]);

    const [dropdownInput, setDropdownInput] = useState(
        currentFilter?.condition
    );

    useEffect(() => {
        setDropdownInput(currentFilter?.condition);
        setValue(currentFilter?.value[0]);
        setEndValue(currentFilter?.value[1]);
    }, [currentFilter]);

    const dropdownItems = DateSelectConditions.map((id) => ({
        id,
        displayValue: filterConditionToDisplayValueMap[id],
    }));

    useEffect(() => {
        if (!value || dropdownInput === undefined) return;

        if (dropdownInput === "between" && !endValue) return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: DateFilter = {
                value:
                    dropdownInput === "between" ? [value, endValue!] : [value],
                condition: dropdownInput,
                field: filterField,
                key: filterKey,
            };

            const index = copy.dateFilters.findIndex(
                ({ field }) => field === filterField
            );
            if (index === -1) copy.dateFilters.push(newFilter);
            else copy.dateFilters[index] = newFilter;
            return copy;
        });
    }, [dropdownInput, value, endValue]);

    const updateValue = (value: string | null) => {
        if (value === null) setValue(undefined);
        else setValue(value);
    };

    const updateEndValue = (value: string | null) => {
        if (value === null) setEndValue(undefined);
        else setEndValue(value);
    };

    return (
        <div
            className={classNames(
                "filter",
                dropdownInput === "between" && "filter--between"
            )}
        >
            <SingleSelectDropdown
                items={dropdownItems}
                onSelectItem={(item) => setDropdownInput(item.id)}
                defaultItem={dropdownItems.find(
                    ({ id }) => id === dropdownInput
                )}
                classname={classNames(
                    "filter__dropdown",
                    dropdownInput === "between" && "filter__dropdown--between"
                )}
            />
            <MUITheme primaryColor="#EB03AD">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className="date-picker"
                        format="DD/MM/YYYY"
                        onChange={updateValue}
                        slotProps={{ textField: { size: "small" } }}
                        value={value || null}
                    />
                    {dropdownInput === "between" && (
                        <DatePicker
                            className="date-picker"
                            format="DD/MM/YYYY"
                            onChange={updateEndValue}
                            slotProps={{ textField: { size: "small" } }}
                            value={endValue || null}
                        />
                    )}
                </LocalizationProvider>
            </MUITheme>
        </div>
    );
}

export default FiltersSidebar;
