import { useAuth0 } from "@auth0/auth0-react";
import { LogOutIcon } from "components/atoms/Icon";
import { Button } from "components/atoms/Button";
import posthog from "posthog-js";
import HoverableDropdown from "components/atoms/HoverableDropdown/HoverableDropdown";
import { useUpdateActiveOrganization } from "mutations/useUpdateActiveOrganization";
import { Paragraph } from "../../atoms/Typography";
import styles from "./HeaderDropDownMenu.module.scss";

interface HeaderDropdownMenuProps {
    userEmail: string;
    customerAccounts: CustomerAccount[];
    activeOrganization: string;
}

function HeaderDropdownMenu({ 
        userEmail, 
        customerAccounts, 
        activeOrganization 
    }: HeaderDropdownMenuProps) {

    const { logout } = useAuth0();

    const { mutate } = useUpdateActiveOrganization();

    const handleOrganizationChange = (newActiveOrganizationUid: string) => {
        const newActiveOrganizationUidObject = {
            activeOrganizationUid: newActiveOrganizationUid
        }
        mutate(newActiveOrganizationUidObject);
    };

    return (
        <div>

                <HoverableDropdown

                        userEmail={userEmail ? userEmail[0].toUpperCase() : ""}
                        customerAccounts={customerAccounts}
                        activeOrganization={activeOrganization}
                        dropdownElements={
                            <>
                                {customerAccounts.map((customerAccount, index) => (
                                    <Button
                                        className={`
                                            headerButton 
                                            headerCustomerAccount
                                            ${customerAccount.organizationName === activeOrganization ? styles.headerActiveCustomerAccountContainer : "" }
                                            ${index === customerAccounts.length - 1 ? "headerCustomerAccountLast" : ""}    
                                        `}
                                        key={customerAccount.organizationUid}
                                        onClick={() =>
                                            handleOrganizationChange(
                                                customerAccount.organizationUid
                                            )
                                        }                                    >
                                        <span>{customerAccount.organizationName}</span>
                                        { customerAccount.organizationName === activeOrganization 
                                        && (<span className={styles.headerActiveCustomerAccount}>
                                                Signed In
                                            </span>
                                        )}
                                    </Button>
                                ))}

                                {/* Uncomment when working on the users with `create_customer_account` permission can create new Customer Accounts story  */}
                                {/* <Button
                                    // type="primary"
                                    id="nav-bar-sign-out-button"
                                    className="headerButton"
                                >
                                    <Paragraph className="logOutContainer">
                                        <PlusIcon />
                                        New organization
                                    </Paragraph>
                                </Button> */}

                                <Button
                                    id="nav-bar-sign-out-button"
                                    onClick={() => {
                                        logout({
                                            returnTo: window.location.origin,
                                        });
                                        posthog.capture("User signed out");
                                    }}
                                    className="headerButton"
                                >
                                    <Paragraph className="logOutContainer">
                                        <LogOutIcon />
                                        Sign Out
                                    </Paragraph>
                                </Button>
                            </>
                        }
                    />
        </div>
    );
}

export default HeaderDropdownMenu;
